import { Navigate, useLocation } from "react-router-dom";
import { LocalStorageKeys } from "../../../constants";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { getUser } from "../../../../services/user";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [user, setUser] = useState(null);
  const location = useLocation();

  useEffect(() => {
    getUser()
      .then((res) => {
        setUser(res);
      })
      .catch((err) => console.error(err));
  }, []);

  // Check if the user is authenticated (e.g., by checking the access token in local/session storage)
  const isAuthenticated = () => {
    const accessToken = localStorage.getItem(LocalStorageKeys.TOKEN);
    const tokenExpirationTime = localStorage.getItem(
      LocalStorageKeys.TOKENDURATION
    );

    if (!accessToken || !tokenExpirationTime) {
      return false;
    }

    const tokenExpirationDate = dayjs(tokenExpirationTime);
    const currentDate = dayjs();

    return currentDate.isBefore(tokenExpirationDate);
  };

  if (isAuthenticated()) {
    return <Component {...rest} />;
  } else {
    return <Navigate to={{ pathname: "/login", state: { from: location } }} />;
  }
};

export default React.memo(PrivateRoute);
