import React, { useState } from "react";
import { LuArrowBigLeftDash } from "react-icons/lu";
// import styles from "./dashboard.module.scss";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const UserGuideLayout = (props) => {
  const [selectedOption, setSelectedOption] = useState("both");
  const [guide, setGuide] = useState(0);
  const navigate = useNavigate();

  return (
    <>
      <div
        className="min-h-screen bg-cover bg-center bg-no-repeat text-center p-6 flex  justify-center"
        style={{ backgroundImage: "url('/img/guide-bg.jpeg')" }}
      >
        <main className="relative flex-1 overflow-y-auto focus:outline-none">
          <div className="">
            <a href="/">
              <button className="flex flex-row items-center font-semibold align-middle text-business-primary border border-business-primary border-2 px-4 py-2 rounded-md hover:text-white hover:bg-business-primary">
                <i className="mr-2">
                  <LuArrowBigLeftDash />
                </i>
                <span>Exit Guide</span>
              </button>
            </a>
          </div>
          <div className="w-full flex flex-col items-center justify-center ">
            {guide === 0 && (
              <div className="rounded-xl w-2/5 bg-white mx-auto py-8 px-16 my-4">
                <h2 className="text-gray-900 mb-8 text-xl">
                  Getting started with my750hrstracker
                </h2>
                <h5 className="text-gray-900 mb-4 text-lg w-4/5 mx-auto mb-8">
                  Welcome to my750hrstracker—your go-to tool for tracking real
                  estate professional hours with ease. Let’s get you set up in
                  just a few steps!
                </h5>

                <button
                  onClick={() => setGuide(1)}
                  className="font-semibold text-white hover:text-business-primary bg-business-primary hover:bg-business-secondary px-4 py-2 rounded-full w-3/5"
                >
                  Next
                </button>
              </div>
            )}
            {guide === 1 && (
              <div className="rounded-xl w-2/5 bg-white mx-auto py-8 px-16 my-4">
                <h2 className="text-gray-900 mb-8 text-xl">
                  What will you track?
                </h2>
                <div className="text-left">
                  <h2 className="font-semibold text-md mb-4">
                    Step 1: Define your tracking needs
                  </h2>
                  <p className="mb-4">
                    You can use my750hrstracker to log hours for both Short-Term
                    Rentals (STR) and Long-Term Rentals (LTR). Each property
                    type has its own rules for qualifying for real estate
                    professional status.
                  </p>
                  <h2 className="mb-4 font-semibold text-md">
                    I'll be tracking...
                  </h2>
                  <div className="space-y-2 mb-4">
                    {[
                      "Long Term Rentals",
                      "Short Term Rentals",
                      "Both",
                      "Not sure yet",
                    ].map((option) => (
                      <label
                        key={option}
                        className="flex items-center space-x-3 cursor-pointer"
                      >
                        <input
                          type="radio"
                          value={option.toLowerCase().replace(/\s+/g, "-")}
                          checked={
                            selectedOption ===
                            option.toLowerCase().replace(/\s+/g, "-")
                          }
                          onChange={(e) => setSelectedOption(e.target.value)}
                          className="form-radio h-5 w-5 text-blue-600 focus:ring-blue-500"
                        />
                        <span>{option}</span>
                      </label>
                    ))}
                  </div>
                  <h2 className="mb-4 font-semibold text-md">
                    You can update this in your account settings anytime
                  </h2>
                  <p className="mb-4">
                    Need more details? Click{" "}
                    <a href="/" className="text-business-primary">
                      here
                    </a>
                    to learn about LTR and STR qualification rules.
                  </p>
                </div>

                <div className="mt-4 flex md:flex-row flex-col items-center">
                  <button
                    onClick={() => setGuide(0)}
                    className="font-semibold text-business-primary border border-business-primary border-2 mr-2 hover:text-white hover:bg-business-primary px-4 py-2 rounded-full w-3/5"
                  >
                    Back
                  </button>
                  <button
                    onClick={() => setGuide(2)}
                    className="font-semibold text-white hover:text-business-primary bg-business-primary hover:bg-business-secondary px-4 py-2 rounded-full w-3/5"
                  >
                    Next
                  </button>
                </div>
              </div>
            )}

            {/* third guide */}
            {guide === 2 && (
              <div className="rounded-xl w-2/5 bg-white mx-auto py-8 px-16 my-4">
                <h2 className="text-gray-900 mb-8 text-xl">Your first steps</h2>
                <div className="text-left">
                  <h2 className="font-semibold text-md mb-4">
                    Step 2: Add your properties
                  </h2>
                  <p className="mb-4">
                    Start by adding the properties you’ll be tracking hours for.
                    Activities require linking to a specific property, so this
                    step ensures everything is accounted for.
                  </p>
                  <h2 className="mb-4 font-semibold text-md">
                    2. Invite your team
                  </h2>
                  <p className="mb-4">
                    Have a spouse or CPA? You can add them from the Team Members
                    screen so they can log hours on your behalf. You can also
                    track their hours alongside yours.
                  </p>
                  <h2 className="mb-4 font-semibold text-md">
                    3. Customize your activities
                  </h2>
                  <p className="mb-4">
                    We’ve pre-loaded an extensive list of common material
                    participation activities, you can add, edit, or categorize
                    them based on your needs. Check with your CPA to ensure
                    compliance when making adjustments.
                  </p>
                  <div className="">
                    <img src="/img/dashboard-1.png" alt="dashboard-1" />
                  </div>
                  <h2 className="mb-4 font-semibold text-md">
                    4. Log your first entry
                  </h2>
                  <p className="mb-4">
                    Now that everything’s set up, it’s time to start tracking!
                    As you log hours, your progress will update on the
                    dashboard, giving you a clear view of where you stand.
                  </p>
                  <p>
                    Pro Tip: Stay consistent with logging hours to avoid
                    last-minute scrambles at tax time!
                  </p>
                  <div className="">
                    <img src="/img/dashboard-2.png" alt="dashboard-2" />
                  </div>
                </div>

                <div className="mt-4 flex md:flex-row flex-col items-center">
                  <button
                    onClick={() => setGuide(1)}
                    className="font-semibold text-business-primary border border-business-primary border-2 mr-2 hover:text-white hover:bg-business-primary px-4 py-2 rounded-full w-3/5"
                  >
                    Back
                  </button>
                  <button
                    onClick={() => setGuide(3)}
                    className="font-semibold text-white hover:text-business-primary bg-business-primary hover:bg-business-secondary px-4 py-2 rounded-full w-3/5"
                  >
                    Finish
                  </button>
                </div>
              </div>
            )}
            {/* last guide */}
            {guide === 3 && (
              <div className="rounded-xl w-2/5 bg-white mx-auto py-8 px-16 my-4">
                <h2 className="text-gray-900 mb-8 text-xl">All set</h2>
                <h5 className="text-gray-900 mb-4 text-lg w-4/5 mx-auto mb-8">
                  Your onboarding is complete. You’re ready to start tracking
                  time
                </h5>

                <button
                  onClick={() => navigate("/")}
                  className="font-semibold text-white hover:text-business-primary bg-business-primary hover:bg-business-secondary px-4 py-2 rounded-full w-3/5"
                >
                  Let's go
                </button>
              </div>
            )}
          </div>
        </main>
      </div>
    </>
  );
};

export default UserGuideLayout;
