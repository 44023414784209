import React, { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { MenuAlt1Icon } from "@heroicons/react/outline";
import { useUserDetail } from "../../../../hooks/user";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../../utils/utils/utils";
import { useStore } from "../../../../store";
import { getUser } from "../../../../services/user";
import { signout } from "../../../../services/auth";

function classNames(...classes) {
  return classes.join(" ");
}
export default function Header(props) {
  const { rentalType } = useStore();
  const { email, name } = useUserDetail();
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [showSubscriptionAlert, setShowSubscriptionPageAlert] = useState(false);

  useEffect(() => {
    getUser()
      .then((res) => {
        setUser(res);

        if (!res?.subscription) {
          setShowSubscriptionPageAlert(true);
        }
      })
      .catch((err) => console.error(err));
  }, []);

  const handleLogout = () => {
    signout().then((res) => {
      logout();
      navigate("/signin");
    });
  };
  return (
    <>
      <div className="relative z-20 flex flex-shrink-0 h-16 bg-white shadow">
        <button
          type="button"
          className="px-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus-ring-business-primary md:hidden"
          onClick={() => props.showSidebar()}
        >
          <span className="sr-only">Open Sidebar</span>
          <MenuAlt1Icon className="w-6 h-6" aria-hidden="true" />
        </button>
        <div className="flex justify-between flex-1 px-4 sm:px-10">
          <div className="flex flex-col md:flex-row items-center">
            <span className="font-semibold text-business-primary text-sm md:text-md pt-2 pb-1 md:py-4 text-nowrap">
              Current Property Type:{" "}
            </span>
            <span className="md:ml-2 uppercase font-bold text-left w-full">
              {rentalType}
            </span>
          </div>
          <Menu as="div" className="relative ml-3 z-50 flex items-center">
            <div className="">
              <Menu.Button className="flex items-center max-w-xs space-x-4 text-sm bg-white focus:outline-none">
                <span className="sr-only">Open user menu</span>
                <div className="flex flex-col items-end text-sm">
                  <div>
                    <span className="font-bold text-right">{name}</span>
                  </div>
                  <div className="text-right">
                    <small>{email}</small>
                  </div>
                </div>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.0775 6.07742L17.256 7.25593L10.0001 14.5119L2.74414 7.25593L3.92265 6.07742L10.0001 12.1548L16.0775 6.07742Z"
                    fill="#C9C9C9"
                  />
                </svg>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-gemspay-primary ring-opacity-5 focus:outline-none z-50">
                <Menu.Item>
                  {({ active }) => (
                    <span
                      onClick={handleLogout}
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                      )}
                    >
                      Log out
                    </span>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      {showSubscriptionAlert && (
        <div className="w-full text-center px-10 py-3 my-4 bg-business-primary text-white w-4/5 mx-auto rounded-lg">
          <span>You do not have active subscription. Click to subscribe</span>
          <span>
            <a href="/settings?subscription=on" className="">
              <button className="py-1 px-4 bg-white text-business-primary rounded-lg ml-8 hover:bg-business-secondary hover:text-white font-semibold">
                Subscribe
              </button>
            </a>
          </span>
        </div>
      )}
    </>
  );
}
