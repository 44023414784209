import React from "react";
import Select from "react-select";
import { useField, useFormikContext } from "formik";

const SearchableSelect = ({ label, options, changeFn, ...props }) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  return (
    <div style={{ marginBottom: "16px" }}>
      {label && (
        <label className="capitalize" htmlFor={props.id || props.name}>
          {label}
        </label>
      )}
      <Select
        options={options}
        id={props.id || props.name}
        name={props.name}
        value={
          options ? options.find((option) => option.value === field.value) : ""
        }
        onChange={(option) => {
          if (changeFn) {
            changeFn(option.value);
          }
          setFieldValue(props.name, option.value);
        }}
        onBlur={field.onBlur}
        placeholder="Select an option"
        isSearchable
        className={props.className}
      />
      {meta.touched && meta.error ? (
        <div style={{ color: "red", fontSize: "12px" }}>{meta.error}</div>
      ) : null}
    </div>
  );
};

export default SearchableSelect;
