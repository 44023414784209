export const LocalStorageKeys = {
  TOKEN: "token",
  TOKENDURATION: "tokenDuration",
  USER: "750hrs-user",
  PERMISSIONS: "userPermissions",
  RENTAL_TYPE: "rentalType",
  SUBSCRIPTION: "subscription",
};

export const NotificationTypes = {
  SUCCESS: "success",
  ERROR: "error",
  INFO: "info",
  WARN: "warn",
  UPLOAD: "upload",
};

export const propertyType = {
  str: "STR",
  ltr: "LTR",
};

export const recordCategoryKeys = {
  generalActivity: "1de58661-97bf-49be-8fe1-08dc3d8861d7",
  materialParticipation: "321a79e2-e8d6-420c-8fe2-08dc3d8861d7",
  attemptedPropertyAcquisitions: "a7dc0d53-2b31-4ad9-27df-08dc3d8861ee",
  leasingAndBrokerage: "2a1ee07b-ef9b-414b-27e0-08dc3d8861ee",
  realEstateProfessionalEducation: "cf19048d-5435-44f5-27e1-08dc3d8861ee",
  generalNetworking: "1f3e33f0-3019-49a4-27e2-08dc3d8861ee",
  investorHours: "87ef3aef-1f0d-4df7-27e3-08dc3d8861ee",
  successfulPropertyAcquisition: "e0f7d011-cb7c-457d-27e4-08dc3d8861ee",
  propertyManagementAndOperations: "1bb98dae-9ec0-4ba9-27e5-08dc3d8861ee",
  propertyConstructionReconstruction: "2dd698fe-2842-4d86-27e6-08dc3d8861ee",
};
