import axios from "axios";
import { LocalStorageKeys } from "../app/constants";

const base = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    "pub-access-key": process.env.REACT_APP_PUBLIC_ACCESS_KEY,
  },
});

base.interceptors.request.use((config) => {
  const token = localStorage.getItem(LocalStorageKeys.TOKEN);

  if (token) config.headers["Authorization"] = `Bearer ${token}`;

  return config;
});

// Handle 401 errors and redirect to login
base.interceptors.response.use(
  (response) => response, // Pass successful responses
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem(LocalStorageKeys.TOKEN); // Clear token
      window.location.href = "/login"; // Redirect to login
    }
    return Promise.reject(error);
  }
);

export default base;
