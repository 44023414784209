import React from "react";
import styles from "./chart-cards.module.scss";
import ChartCard from "../chart-card/chart-card.component";

const ChartCards = ({ chartData, data }) => {
  //LTR
  // Extracting totalRepsHours
  const totalRepsHours = chartData?.data?.totalRepsHours || 0;
  const ltrTotalTeamMembers = chartData?.data?.teamMembersCount || 0;

  const logCatergories = (chartData?.data?.logHours || [])
    .filter((log) => log.logType.toLowerCase() === "real_estate")
    .flatMap((log) => log.categories || []);

  // Extracting userHours where categories.name is "General Activity" and isAdmin is true
  const generalActivityAdminHours = logCatergories
    .filter((cat) => cat.slug === "general_activity")
    .flatMap((cat) => cat.userHours || [])
    .filter((userHour) => userHour.isAdmin)
    .reduce((acc, userHour) => acc + userHour.hours, 0);

  // console.log(generalActivityAdminHours);

  // Extracting userHours where categories.name is "General Activity" and isSpouse is true
  const generalActivitySpouseHours = logCatergories
    .filter((cat) => cat.slug === "general_activity")
    .flatMap((cat) => cat.userHours || [])
    .filter((userHour) => userHour.isSpouse)
    .reduce((acc, userHour) => acc + userHour.hours, 0);

  const totalGeneralActivityHours = logCatergories
    ?.filter((cat) => cat.slug === "general_activity")
    .flatMap((cat) => cat)[0]?.hours;

  // Extracting userHours where categories.slug is "material_participation" and isAdmin is true
  const materialParticipationAdminHours = (chartData?.data?.logHours || [])
    .filter((log) => log.logType.toLowerCase() === "real_estate")
    .flatMap((log) => log.categories || [])
    .filter((cat) => cat.slug === "material_participation")
    .flatMap((cat) => cat.userHours || [])
    .filter((userHour) => userHour.isAdmin)
    .reduce((acc, userHour) => acc + userHour.hours, 0);

  // Extracting userHours where categories.slug is "material_participation" and isSpouse is true
  const materialParticipationSpouseHours = (chartData?.data?.logHours || [])
    .filter((log) => log.logType.toLowerCase() === "real_estate")
    .flatMap((log) => log.categories || [])
    .filter((cat) => cat.slug === "material_participation")
    .flatMap((cat) => cat.userHours || [])
    .filter((userHour) => userHour.isSpouse)
    .reduce((acc, userHour) => acc + userHour.hours, 0);

  const totalMaterialParticipationHours = logCatergories
    ?.filter((cat) => cat.slug === "material_participation")
    .flatMap((cat) => cat)[0]?.hours;

  const totalRepsSpouseHours =
    generalActivityAdminHours + materialParticipationAdminHours;
  const totalRepsAdminHours =
    generalActivitySpouseHours + materialParticipationSpouseHours;

  //STR
  const totalStrHours = chartData?.data?.totalRepsHours || 0;
  const totalHoursDstr =
    chartData?.data?.logHours?.reduce((acc, log) => acc + log.totalHours, 0) ||
    0;
  const strTotalTeamMembers =
    chartData?.data?.userHours?.reduce((acc, hrs) => acc + hrs.hours, 0) || 0;

  const ltrDataArray = [
    {
      ischartOption: true,
      isDoughnut: true,
      heading: "Total REPS Hours",
      value: `${totalRepsHours} Hours`,
      doughnutData: {
        labels: {
          Partner: totalRepsSpouseHours,
          Owner: totalRepsAdminHours,
        },
        bgColor: {
          Partner: "#FFECE5",
          Owner: "#F56630",
        },
      },
    },
    {
      ischartOption: true,
      isDoughnut: true,
      heading: "General RE Hours",
      value: `${totalGeneralActivityHours} Hours`,
      doughnutData: {
        labels: {
          Owner: generalActivityAdminHours,
          Partner: generalActivitySpouseHours,
        },
        bgColor: {
          bgColor1: "#2DBA21",
          bgColor2: "#075201",
        },
      },
    },
    {
      ischartOption: true,
      isDoughnut: true,
      heading: "Material Hours",
      value: `${totalMaterialParticipationHours} Hours`,
      doughnutData: {
        labels: {
          Owner: materialParticipationAdminHours,
          Partner: materialParticipationSpouseHours,
        },
        bgColor: {
          Owner: "#FEF6E7",
          Partner: "#F19A02",
        },
      },
    },
    {
      ischartOption: true,
      isDoughnut: false,
      heading: "Team Members",
      value: `${ltrTotalTeamMembers} Members`,
    },
  ];

  const strDataArray = [
    {
      ischartOption: false,
      isDoughnut: true,
      heading: "Total STR Hours",
      value: `${totalStrHours} Hours`,
      doughnutData: {
        labels: {
          Partner: totalRepsSpouseHours,
          Owner: totalRepsAdminHours,
        },
        bgColor: {
          bgColor1: "#FEF6E7",
          bgColor2: "#F19A02",
        },
      },
    },
    {
      ischartOption: true,
      isDoughnut: true,
      heading: "Hours Distribution",
      value: `${totalHoursDstr} Hours`,
      doughnutData: {
        labels: {
          Owner: "You",
          Partner: "Spouse",
        },
        bgColor: {
          bgColor1: "#FFECE5",
          bgColor2: "#F56630",
        },
      },
    },
    {
      ischartOption: true,
      isDoughnut: false,
      heading: "Team Members",
      value: `${ltrTotalTeamMembers} Members`,
      doughnutData: {
        labels: {
          label1: materialParticipationAdminHours,
          label2: materialParticipationSpouseHours,
        },
        bgColor: {
          bgColor1: "#3247FC",
          bgColor2: "#3247FC",
        },
      },
    },
  ];

  const chartsDataArray =
    chartData?.data?.propertyType === "LTR" ? ltrDataArray : strDataArray;

  return (
    <section className="grid grid-cols-1 md:grid-cols-4 w-full gap-4">
      {chartsDataArray.map((data, index) => (
        <ChartCard
          key={index}
          chartData={data}
          isDoughnut={data?.isDoughnut}
          ischartOption={data?.ischartOption}
        />
      ))}
    </section>
  );
};

ChartCards.defaultProps = {
  data: Array.from({ length: 3 }),
};

export default ChartCards;
