import { Fragment, useEffect, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { switchTeam } from "../../../../services/user";
import { showToast } from "../showToast/showToast";
import { NotificationTypes } from "../../../constants";

export default function TeamSwitcher({ user }) {
  const [teams, setTeams] = useState([]);
  const [currentTeam, setCurrentTeam] = useState([]);

  useEffect(() => {
    if (user) {
      setTeams(user.teams);
      let userTeam = user?.teams?.filter(
        (team) => team.id === user.defaulTeamId
      );
      if (userTeam) {
        setCurrentTeam(userTeam[0]);
      }
    }
  }, [user]);

  const [query, setQuery] = useState("");

  const filteredTeamsList =
    query === ""
      ? teams
      : teams.filter((team) =>
          team.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  const handleTeamSwitch = (team) => {
    console.log(team, currentTeam);
    if (currentTeam.id === user.defaultTeamId) {
      return;
    }
    setCurrentTeam(team);
    posTeamSwitch(team);
  };

  const posTeamSwitch = async (team) => {
    try {
      await switchTeam({
        id: user.id,
        toTeamId: team.id,
      });
      window.location.reload();
    } catch (error) {
      if (error && !error.response) {
        showToast(
          `Please try again or contact support.`,
          NotificationTypes.ERROR
        );
        return;
      }
    }
  };

  return (
    <Combobox value={currentTeam} onChange={handleTeamSwitch}>
      <div className="relative mt-1">
        <div className="relative w-full text-left text-business-secondary rounded-l-lg py-2 shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-business-primary focus-visible:ring-offset-2 sm:text-sm overflow-hidden">
          <Combobox.Label
            className={"text-business-primary font-semibold pl-3"}
          >
            Switch Team:
          </Combobox.Label>
          {/* <Combobox.Input
            className="w-full bg-business-darkAlt border-none focus:ring-0 py-2 pl-3 pr-10 text-sm leading-5 font-semibold text-business-lightAlt"
            displayValue={(currentTeam) => currentTeam.name}
            onChange={(event) => setQuery(event.target.value)}
          /> */}
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <SelectorIcon
              className="w-5 h-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery("")}
        >
          <Combobox.Options className="absolute w-full bg-white py-1 mt-1 overflow-auto text-base rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredTeamsList &&
            filteredTeamsList?.length === 0 &&
            query !== "" ? (
              <div className="cursor-default select-none relative py-2 px-4 text-gray-700">
                Nothing found.
              </div>
            ) : (
              filteredTeamsList?.map((team) => (
                <Combobox.Option
                  key={team.id}
                  className={({ active }) =>
                    `cursor-pointer select-none relative capitalize py-2 pl-10 pr-4 ${
                      active ? "text-business-primary" : "text-gray-900"
                    }`
                  }
                  value={team}
                >
                  {({ currentTeam, active }) => (
                    <>
                      <span
                        className={`block truncate ${
                          currentTeam || active
                            ? "font-medium text-lg"
                            : "font-normal"
                        }`}
                      >
                        {team.name}
                      </span>
                      {currentTeam ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                            active ? "text-white" : "text-business-primary"
                          }`}
                        >
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
}
