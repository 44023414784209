import base from "../../libs/axios";

export const getChartData = (params = {}) => {
  const { propertyType = "ALL", summaryType } = params;

  return base
    .get(
      `/api/dashboard/get-data?propertyType=${propertyType}&summaryType=${summaryType}`
    )
    .then(({ data }) => data)
    .catch((err) => {
      console.error(
        "Error getting chart data:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });
};
